import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
// import "../assets/fonts/notosans/stylesheet.css"
import "../assets/fonts/jost/stylesheet.css"
import "../assets/fonts/montserrat/stylesheet.css"
import "../assets/fonts/ionicons.min.css"
import "../assets/fonts/font-awesome.min.css"

// <!-- Vendor CSS style -->
import "../assets/css/pageloader.css"

// <!-- Uncomment below to load individualy vendor CSS -->
import "../assets/css/bootstrap.min.css"

import "../assets/js/vendor/swiper/swiper.min.css"

// <!-- Main CSS files -->
import "../assets/css/main.css"

// <!-- add alternative CSS rules here -->
import "../assets/css/style-default.css"
import "../assets/css/pageloader.css"
import "../assets/css/ed.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div id="menu" Class="body-page">
      <Header siteTitle={data.site.siteMetadata.title}/>
      <main className="page-main bg-black-6 " id="mainpage">{children}</main>
      <Footer/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
