import React from "react"
import Layout from "../layout/master"

const AboutPage = () => (
  <Layout>
    <section id="contact" className="section section-page section-contact section-margin ">
      <div className="section-cover"></div>
      <div className="section-header align-x-center">
        <div className="h-content width-medium">
          <h2 className="h-title font-title sr-up-1">
            My story
          </h2>
        </div>
      </div>

      <div className="section-content align-x-center">
        <div className="c-wrapper width-medium mb-6">
          <div className="row no-gutters">
            <div className="col col-12">
              <div className="content-text sr-up-2">
                <p className="mb-0">
                  So I started as a young, budding chap about 5 years ago, picking up C# in school when I thought my goal in life was to work at Microsoft.
                  It didn't work out! Thoroughly determined to learn web dev i picked up wordpress along the way, loving every moment of uploading pages onto a cms and lying
                  to people that I actually understood what the cms was doing...until I discovered Laravel. I was absolutely floored by it's simplicity and beauty.
                  And thus began my fullstack journey, picking up javascript along the way, fell in love with Vue. I then came across NodeJs and got into a complicated
                  relationship with it. Then Spring got into the picture...it changed my perspective on what a good developer really looks like. I got some good
                  nights in trying to wrap my head around Java, which also allowed me to get into Android. Then flask came along and I realised I probably just
                  loved learning. I can't wait to see what else comes along...it's been pretty cool so far
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>

)

export default AboutPage
