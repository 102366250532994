import React from "react"

const Footer = () => {
  return (
    <footer id="site-footer" className="page-footer d-none d-lg-block loading-anim">
      {/*Left Content*/}
      <div className="footer-left f-content">
        <a className="btn btn-line-a px-0 pb-0 scroll-down">
        <span className="icon icon-menu icon-arrow-a icon-anim">
          <span className="arrow-down"></span>
        </span>
          <span className="text">Scroll</span>
        </a>
      </div>

      {/*Right Content*/}
      <div className="footer-right f-content">
        <div className="separator-line"></div>
        <div className="f-text-modern">
          <a href="http://cutekit.net/">
            <span className="marked">contact@hello.com</span>
          </a>
        </div>
        <ul className="f-social">
          <li><a href="http://twitter/miradontsoa"><i className="icon fa fa-twitter"></i></a></li>
          <li><a href="http://instagram.com/miradontsoa"><i className="icon fa fa-instagram"></i></a></li>
        </ul>
      </div>
    </footer>


  )
}

export default Footer
