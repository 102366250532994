import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    {/* Begin of navbar-top */}

    <nav className="navbar navbar-top ">
      <div className="navbar-group">
        <Link to={'/'} className="navbar-brand">
          <span className="logo">
            <img className="light-logo " src={require('../assets/img/logo_white.png')} alt="Logo"/>
          </span>
          <span className="text">
            {/*<span className="line strong">Ed</span>*/}
            <span className="line">Home</span>
          </span>
        </Link>
      </div>

      <Link to={'/contact'} className="menu-toggler btn btn-line-a py-3 py-md-0">
        <span className="icon icon-menu icon-arrow-a icon-anim">
              <span className="arrow-right"></span>
            </span>
        <span className="text t-upper fs-normal d-none d-md-inline-block">Hire Me</span>
      </Link>
    </nav>
    {/* End of navbar-top */}

    {/* Begin of navbar-sidebar */}
    <nav className="navbar navbar-sidebar ">
      <div className="navbar-group">
        <div className="navbar-collapse">
          <ul className="navbar-nav nav-menu">
            <li className="nav-item t-upper fs-normal">
              <Link to={"/about"}>About</Link>
            </li>
            <li className="nav-item t-upper fs-normal">
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    {/* End of navbar-sidebar */}

  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
